import React from "react";
import { useSelector } from "react-redux";
import { createOptions } from "../../../../utils";
import { getClientOptions } from "../../../../selectors/dropdowns";
import { FilterMainComponentProps } from "../Filter";
import { Select } from "@commonsku/styles";

interface ClientOption {
  label: string;
  value: string;
}

interface ClientSelectProps extends FilterMainComponentProps {
  values: string | string[];
}

const ClientSelect = ({
  values,
  onChange,
  placeholder = "All",
  isMulti = false,
}: ClientSelectProps) => {
  const clientOptions: ClientOption[] = createOptions(
    useSelector(getClientOptions),
    "key",
    "value",
  );

  return (
    <Select
      value={
        (isMulti && Array.isArray(values))
          ? clientOptions.filter((op) => values.includes(op.value))
          : clientOptions.find((op) => op.value === values)
      }
      isMulti={isMulti}
      // @ts-ignore
      onChange={(selected) => {
        let value: string | string[] = "";
        if (selected) {
          if (Array.isArray(selected) || isMulti) {
            value = (selected as ClientOption[]).map((op) => op.value);
          } else {
            value = (selected as ClientOption).value;
          }
        }
        onChange(value);
      }}
      isClearable={values !== "" && (!Array.isArray(values) || values.length > 0)}
      // @ts-ignore
      options={clientOptions}
      placeholder={placeholder}
    />
  );
};

export default ClientSelect;
